<template>
  <div>
    <section class="py-5 my-5">
      <div class="container">
        <h1 class="mb-5">Ajustes de usuario</h1>
        <div class="bg-white shadow rounded-lg d-block d-sm-flex">
          <div class="profile-tab-nav border-right">
            <div class="p-4">
              <h4 class="text-center">{{ getFullName }}</h4>
            </div>
          </div>
          <div class="tab-content p-4 p-md-5" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="account"
              role="tabpanel"
              aria-labelledby="account-tab"
            >
              <h3 class="mb-4">Ajustes de usuario</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <input
                      type="text"
                      v-model="first_name"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Apellido</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="last_name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      required
                      v-model="email"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Telefono</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Company</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      :value="company_name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <form
                      action="https://www.paypal.com/cgi-bin/webscr"
                      method="post"
                      target="_top"
                    >
                      <input
                        type="hidden"
                        name="cmd"
                        value="_xclick-subscriptions"
                      />
                      <input
                        type="hidden"
                        name="business"
                        value="frederickpeal@gmail.com"
                      />
                      <input type="hidden" name="lc" value="US" />
                      <input
                        type="hidden"
                        name="item_name"
                        value="ICG Canela Reporte"
                      />
                      <input type="hidden" name="no_note" value="1" />
                      <input type="hidden" name="src" value="1" />
                      <input type="hidden" name="a3" value="20.00" />
                      <input type="hidden" name="p3" value="1" />
                      <input type="hidden" name="t3" value="M" />
                      <input type="hidden" name="currency_code" value="USD" />
                      <input
                        type="hidden"
                        name="bn"
                        value="PP-SubscriptionsBF:btn_subscribeCC_LG.gif:NonHostedGuest"
                      />
                      <input
                        type="image"
                        src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif"
                        border="0"
                        name="submit"
                        alt="PayPal - The safer, easier way to pay online!"
                      />
                      <img
                        alt=""
                        border="0"
                        src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                        width="1"
                        height="1"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary" @click="updateUser">
                  Update
                </button>
                <button class="btn btn-light" @click="$router.back()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "user-settings",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
    };
  },
  created() {
    this.first_name = this.getFirstName;
    this.last_name = this.getLastName;
    this.email = this.getEmail;
  },
  methods: {
    updateUser() {
      axios
        .put("/api/users/profile", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
        })
        .then(() => {
          this.$store.dispatch("getProfile");
          this.$router.back();
        });
    },
  },
  computed: {
    ...mapGetters([
      "getFullName",
      "getFirstName",
      "getLastName",
      "getEmail",
      "company_name",
      "getUser",
    ]),
  },
  watch: {
    getUser: function (val) {
      this.email = val.email;
      this.first_name = val.first_name;
      this.last_name = val.last_name;
    },
  },
};
</script>